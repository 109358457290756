import { collection, addDoc} from "firebase/firestore";
import { db } from "../../_config/firebaseConfig"; // Import your Firebase Firestore instance here
import ContactForm from "../../interfaces/ContactForm";
interface EmailingService {
  sendEmail: (data: ContactForm) => Promise<any>;
}

const service: EmailingService = {
  
  sendEmail: async (data: ContactForm) => {
    try {   
      const messageRef = collection(db, "Messages");
      const docRef = await addDoc(messageRef, data);  
      
      return { success: true, id: docRef.id};
    } catch (error) {
      console.error("Firebase error:", error);
      return { success: false, error: error };
    }
  },
  

  
};

export default service;
