import { useNavigate } from "react-router-dom";

export default function FourOFourPage() {
  const navigate = useNavigate();
  return (
    <>
      <main>
        <section className="relative pt-16 pb-32">
          <div className="flex flex-wrap items-center mt-4 ">
            <div className="w-full md:w-10/12 px-4 mr-auto ml-auto">
              <img src="./banners/404Banner.png" alt="404"></img>
            </div>
            <div className="w-full md:w-6/12 px-4 mr-auto ml-auto">
              <button
                className="bg-desert-200 text-blueGray-200 active:bg-blueGray-600 text-xl font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => navigate("/")}
              >
                {"Go Home"}
              </button>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
