import { httpsCallable } from "firebase/functions";
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import PackageItem from "../../interfaces/PackageItem";
import BookForm from "../../interfaces/BookForm";
import { useStateReducer } from "../../hooks";
import service from "../../services/Package/packageService";
import { functions } from "../../_config/firebaseConfig";
import bookingService from "../../services/booking/bookingService";
import moment from "moment";
import ReCAPTCHA from "react-google-recaptcha";
import routes from "../../_config/routes";
import { useNavigate } from "react-router-dom";
interface BookingDataProps {
  bookForm?: BookForm;
}
interface State {
  name?: string;
  email?: string;
  contactNumber?: string;
  packageItem?: PackageItem | null;
  date?: string;
  noOfAdults?: string;
  noOfChildren?: string;
  message?: string;
  errors: {
    name?: string;
    email?: string;
    contactNumber?: string;
    packageItem?: string;
    date?: string;
    recaptcha?: string;
  };

  loading?: boolean;
}
const BookingForm = ({ bookForm }: BookingDataProps) => {
  const [state, setState] = useStateReducer<State>({
    name: bookForm?.name ?? "",
    email: bookForm?.email ?? "",
    contactNumber: bookForm?.contactNumber ?? "",
    packageItem: bookForm?.packageItem ?? undefined,
    date: bookForm?.date ?? "",
    noOfAdults: bookForm?.noOfAdults ?? "1",
    noOfChildren: bookForm?.noOfChildren ?? "0",
    message: bookForm?.message ?? "",
    errors: {},
    loading: false,
  });
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedPackage, setSelectedPackage] = useState<PackageItem>();
  const [packageList, setPackageList] = useState<PackageItem[]>([]);
  const [recaptchaResponse, setRecaptchaResponse] = useState<string | null>(
    null
  );
  const navigate = useNavigate();

  const siteKey = process.env.REACT_APP_SITE_KEY ?? "";
  const fetchPackages = async () => {
    try {
      const response: PackageItem[] = await service.getPackages();

      setPackageList(response);
    } catch (error) {}
  };

  useEffect(() => {
    fetchPackages();
  }, []);
  const handleChange = (prop: string, value: any) => {
    setState({ ...state, [prop]: value });
  };
  const validateForm = () => {
    const errors: { [key: string]: string } = {};

    if (!state.name) {
      errors.name = "Name is required";
    }

    if (!state.email) {
      errors.email = "Email is required";
    } else if (!isValidEmail(state.email)) {
      errors.email = "Invalid email address";
    }

    if (!state.contactNumber) {
      errors.contactNumber = "Contact Number is required";
    }
    if (state.date === null) {
      errors.date = "Date is required";
    }
    if (state.packageItem === null) {
      errors.packageItem = "Package is required";
    }

    if (!recaptchaResponse) {
      errors.recaptcha = "Please verify that you're not a robot";
    }

    setState({ ...state, errors });
    return Object.keys(errors).length === 0;
  };

  const isValidEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };
  // Handle date selection
  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  // Handle package selection
  const handlePackageChange = (event: any) => {
    setSelectedPackage(event.target.value);
  };
  const handleRecaptchaChange = (response: string | null) => {
    setRecaptchaResponse(response);
    state.errors.recaptcha = "";
  };

  // Your form submission function
  const handleSubmit = async (e?: any) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setState({ ...state, loading: true });
        var result: any;

        const adjustedDate = selectedDate
          ? moment(selectedDate).format("YYYY-MM-DD")
          : "";
        var entry = {
          name: state?.name ?? "",
          email: state?.email ?? "", // Set service title
          contactNumber: state?.contactNumber ?? "",
          packageItem: (selectedPackage as PackageItem) ?? "",
          date: adjustedDate ?? "",
          noOfAdults: state?.noOfAdults?.toString() ?? "",
          noOfChildren: state?.noOfChildren?.toString() ?? "",
          message: state?.message ?? "",
          status: "pending",
        } as BookForm;

        try {
          const createEvent = httpsCallable(functions, "/createGoogleEvent");
          result = await createEvent(entry);
          console.log(result.data);

          if (result?.data.success === true && result?.data.id) {
            entry.id = result.data.id;
            await bookingService.postBooking(entry);
            const sendEmailFunction = httpsCallable(
              functions,
              "/sendBookingEmail"
            );
            await sendEmailFunction(entry);

            // Clear the form on success
            setState({
              name: "",
              email: "",
              contactNumber: "",
              packageItem: undefined,
              date: "",
              noOfAdults: "1",
              noOfChildren: "0",
              message: "",
              errors: {},
              loading: false,
            });
            await bookingService.verifyRecaptcha(recaptchaResponse || "");
            navigate(routes.success.raw);
          } else {
            alert(`Failed to create Booking. Please try again.`);
          }
        } catch (error) {
          console.error("Error creating Booking:", error);
          console.log("Error creating Booking:", error);
          alert("Failed to Send Email.");
        }
      } catch (error) {
        console.error("Error creating Booking:", error);
        alert("Failed to Book.");
      } finally {
        setState({ ...state, loading: false });
      }
    }
  };
  return (
    <>
      <main>
        <section className="pb-20 relative block bg-white">
          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-tahiti-800">
                  Your Adventure Awaits!
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-tahiti-800">
                  Embark on an exciting journey with us. Book your adventure now
                  and create unforgettable memories!
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="relative block py-24 lg:pt-0 bg-white">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-100">
                  <div className="flex-auto p-5 lg:p-10">
                    <h4 className="text-2xl font-semibold">
                      Ready to Book Your Adventure?
                    </h4>
                    <p className="leading-relaxed mt-1 mb-4 text-blueGray-600">
                      Fill out the form below, and we&apos;ll ensure you have an
                      amazing experience.
                    </p>
                    <div className="relative w-full mb-3 mt-8">
                      <label
                        className="block uppercase text-blueGray-900 text-xs font-bold mb-2"
                        htmlFor="full-name"
                      >
                        Full Name
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-600 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Full Name"
                        onChange={(e) => handleChange("name", e.target.value)}
                      />
                      {state.errors.name && (
                        <span className="text-red-600">
                          {state.errors.name}
                        </span>
                      )}
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-900 text-xs font-bold mb-2"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="border-0 px-3 py-3 placeholder-blueGray-600 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                        onChange={(e) => handleChange("email", e.target.value)}
                      />
                      {state.errors.email && (
                        <span className="text-red-600">
                          {state.errors.email}
                        </span>
                      )}
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-900 text-xs font-bold mb-2"
                        htmlFor="number"
                      >
                        Contact Number
                      </label>
                      <input
                        type="tel"
                        className="border-0 px-3 py-3 placeholder-blueGray-600 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Contact Number"
                        onChange={(e) =>
                          handleChange("contactNumber", e.target.value)
                        }
                      />
                      {state.errors.contactNumber && (
                        <span className="text-red-600">
                          {state.errors.contactNumber}
                        </span>
                      )}
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-900 text-xs font-bold mb-2"
                        htmlFor="package"
                      >
                        PackageItem
                      </label>
                      <select
                        value={selectedPackage?.title}
                        onChange={handlePackageChange}
                        className="border-0 px-3 py-3 placeholder-blueGray-600 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      >
                        <option value="">Select Package</option>
                        {packageList.map((packageItem) => (
                          <option
                            key={packageItem.id}
                            value={packageItem.title}
                          >
                            {packageItem.title}
                          </option>
                        ))}
                      </select>
                      {state.errors.packageItem && (
                        <span className="text-red-600">
                          {state.errors.packageItem.toString()}
                        </span>
                      )}
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-900 text-xs font-bold mb-2"
                        htmlFor="date"
                      >
                        Date
                      </label>
                      <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat="yyyy-MM-dd"
                        className="border-0 px-3 py-3 placeholder-blueGray-600 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholderText="Select Date"
                      />
                      {state.errors.date && (
                        <span className="text-red-600">
                          {state.errors.date}
                        </span>
                      )}
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-900 text-xs font-bold mb-2"
                        htmlFor="adults"
                      >
                        Number of Adults
                      </label>
                      <input
                        type="number" // Set the input type to "number"
                        className="border-0 px-3 py-3 placeholder-blueGray-600 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="No of Adults"
                        id="adults" // Add an ID to the input for labeling
                        min="1" // Set the minimum allowed value (adjust as needed)
                        max="99" // Set the maximum allowed value (adjust as needed)
                        onChange={(e) =>
                          handleChange("noOfAdults", e.target.value)
                        }
                        defaultValue={1}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-900 text-xs font-bold mb-2"
                        htmlFor="children"
                      >
                        Number of Children
                      </label>
                      <input
                        type="number" // Set the input type to "number"
                        className="border-0 px-3 py-3 placeholder-blueGray-600 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="No of Children"
                        id="children" // Add an ID to the input for labeling
                        min="0" // Set the minimum allowed value (adjust as needed, use 0 for no children)
                        max="99" // Set the maximum allowed value (adjust as needed)
                        onChange={(e) =>
                          handleChange("noOfChildren", e.target.value)
                        }
                        defaultValue={0}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-900 text-xs font-bold mb-2"
                        htmlFor="message"
                      >
                        Message or Special Requests
                      </label>
                      <textarea
                        rows={4}
                        cols={80}
                        className="border-0 px-3 py-3 placeholder-blueGray-600 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Type a message..."
                        onChange={(e) =>
                          handleChange("message", e.target.value)
                        }
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      {siteKey && (
                        <ReCAPTCHA
                          sitekey={siteKey}
                          onChange={handleRecaptchaChange}
                        />
                      )}
                      {state.errors.recaptcha && (
                        <span className="text-red-600">
                          {state.errors.recaptcha}
                        </span>
                      )}
                    </div>
                    <div className="text-center mt-6">
                      <button
                        className="bg-desert-200 text-blueGray-200 active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={handleSubmit}
                        disabled={state.loading} // Disable the button when loading
                      >
                        {state.loading ? "Loading..." : "Book Now"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
export default BookingForm;
