/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import Review from "../../interfaces/Reviews/Review";
import { useStateReducer } from "../../hooks";
import service from "../../services/Reviews/reviewService";

interface State {
  review?: Review[] | null;
  reviewId?: string;
  isSaveDisabled?: boolean;
}

function Carousel() {
  const [state, setState] = useStateReducer<State>({ isSaveDisabled: true });
  const [activeItem, setActiveItem] = useState(0); // Initialize with the first item as active
  const [isLoading, setIsLoading] = useState(true);

  const goToPrev = () => {
    if (activeItem > 0) {
      setActiveItem(activeItem - 1);
    }
  };

  const goToNext = () => {
    if (activeItem < state.review?.length! - 1) {
      setActiveItem(activeItem + 1);
    }
  };

  const load = async () => {
    try {
      const result: Review[] = await service.getReviews();
      setState({ ...state, review: result });
    } catch (error) {
      // Handle errors
    }
  };

  useEffect(() => {
    load();
  }, []);

  // Function to render star ratings
  const renderStarRatings = (rating: any) => {
    const yellowStars = Math.floor(rating); // Number of yellow stars
    const grayStars = 5 - yellowStars; // Number of gray stars

    const starIcons = [];

    // Add yellow stars
    for (let i = 0; i < yellowStars; i++) {
      starIcons.push(
        <svg
          key={i}
          className="w-4 h-4 text-yellow-300"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 22 20"
        >
          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
        </svg>
      );
    }

    // Add gray stars
    for (let i = 0; i < grayStars; i++) {
      starIcons.push(
        <svg
          key={`gray-star-${i}`}
          className="w-4 h-4 text-gray-300"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 22 20"
        >
          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
        </svg>
      );
    }

    return starIcons;
  };
  const handleButtonClick = (index: any) => {
    setActiveItem(index);
  };
  useEffect(() => {
    const delay = 2000;
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, [state.review]);

  return (
    <>
      {!isLoading && state.review ? (
        <div className="mx-auto px-4 max-w-7xl lg:px-5 pb-24 ">
          <div
            id="carouselExampleCaptions"
            style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}
            className="relative pt-8 pb-24 rounded-lg items-center bg-desert-200"
            data-te-carousel-init
            data-te-ride="carousel"
          >
            <h1 className="text-center text-3xl font-semibold leading-6 text-white pb-12 underline">
              Reviews:
            </h1>
            <div
              className="absolute bottom-0 left-0 right-0 z-[2] mx-[15%] -mb-12 flex list-none justify-center p-0"
              data-te-carousel-indicators
            >
              {state.review?.map((item, index) => (
                <button
                  key={index}
                  type="button"
                  data-te-target="#carouselExampleCaptions"
                  data-te-slide-to={index}
                  onClick={() => handleButtonClick(index)}
                  className={`mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-tahiti-800 bg-clip-padding p-0 -indent-[999px] ${
                    activeItem === index ? "opacity-100" : "opacity-50"
                  } transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none`}
                  aria-current={activeItem === index ? "true" : undefined}
                  aria-label={`Slide ${index + 1}`}
                ></button>
              ))}
            </div>
            <div className="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
              {state.review?.map((item, index) => (
                <>
                  <div
                    key={index}
                    className={`relative container float-left -mr-[100%] w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none ${
                      index === activeItem ? "block" : "hidden"
                    }`}
                    data-te-carousel-active
                    data-te-carousel-item
                  >
                    <article>
                      <div className="flex items-center mb-2">
                        <div className=" dark:text-black">
                          <p className="text-white text-2xl">{item?.name}</p>
                        </div>
                      </div>
                      <div className="flex items-center mb-2">
                        <h3 className="ms-0 text-sm font-semibold text-white dark:text-white">
                          {item.title}
                        </h3>
                      </div>
                      <div className="flex items-center mb-3 mt-3 space-x-1 rtl:space-x-reverse">
                        {renderStarRatings(item.rating)}
                      </div>
                      <footer className="mb-5 text-sm text-white dark:text-gray-400">
                        <p>{item.date?.substring(0, 10)}</p>
                      </footer>
                      <p
                        className="mb-2 text-white dark:text-gray-400"
                        style={{
                          maxHeight: "6em", // Limit to 4 lines (adjust as needed)
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "break-spaces", // Ensure proper line breaking
                          display: "-webkit-box",
                          WebkitLineClamp: 6, // Number of lines to show
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {item.comment}
                      </p>
                    </article>
                  </div>
                </>
              ))}
            </div>

            <button
              className="absolute bottom-0 -left-12 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-tahiti-200 hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
              type="button"
              data-te-target="#carouselExampleCaptions"
              data-te-slide="prev"
              onClick={goToPrev}
            >
              <span className="inline-block h-8 w-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                  />
                </svg>
              </span>
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Previous
              </span>
            </button>
            <button
              className="absolute bottom-0 -right-12 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-tahiti-200 hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
              type="button"
              data-te-target="#carouselExampleCaptions"
              data-te-slide="next"
              onClick={goToNext}
            >
              <span className="inline-block h-8 w-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                  />
                </svg>
              </span>
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Next
              </span>
            </button>
          </div>
        </div>
      ) : (
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div
            id="carouselExampleCaptions"
            className="relative pt-8 pb-24 shadow-lg rounded-lg"
          >
            {/* <CarouselSkeleton /> */}
          </div>
        </div>
      )}
    </>
  );
}

export default Carousel;
