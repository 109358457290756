import "./App.css";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import CookiesOverlay from "./components/cookiesOverlay";
import { useState } from "react";

function App() {
  const hasAcceptedCookies = localStorage.getItem("hasAcceptedCookies");
  const [isContentEnabled, setIsContentEnabled] = useState(
    hasAcceptedCookies === "true" || false
  );
  const acceptCookies = () => {
    localStorage.setItem("hasAcceptedCookies", "true");
    setIsContentEnabled(true);
  };

  // Function to decline cookies
  const declineCookies = () => {
    localStorage.setItem("hasAcceptedCookies", "false");
    setIsContentEnabled(true);
  };
  return (
    <BrowserRouter>
      {!isContentEnabled && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 z-50">
          <CookiesOverlay onAccept={acceptCookies} onDecline={declineCookies} />
        </div>
      )}
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
