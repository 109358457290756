export class InterpolatedRoute extends String {
  go(...parts: (string | null | undefined)[]): string {
    const toTest = this.toString();
    const regex = /:[a-zA-Z0-1]+/g;
    let toReturn = toTest;
    let match;
    let counter = 0;

    while ((match = regex.exec(toTest)))
      toReturn = toReturn.replace(match[0], parts[counter++] ?? "#ERROR");

    return toReturn;
  }

  get raw() {
    return this.toString();
  }
}

const routes = {
  home: new InterpolatedRoute("/"),
  about: new InterpolatedRoute("/about"),
  tours: new InterpolatedRoute("/tours"),
  tourItem: new InterpolatedRoute("/tours/:tourId"),
  specials: new InterpolatedRoute("/specials"),
  gallery: new InterpolatedRoute("/gallery"),
  contact: new InterpolatedRoute("/contact"),
  booking: new InterpolatedRoute("/booking"),
  review: new InterpolatedRoute("/review"),
  privacy: new InterpolatedRoute("/privacy"),
  terms: new InterpolatedRoute("/terms"),
  success: new InterpolatedRoute("/success"),
};

export default routes;
