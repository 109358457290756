import { collection, getDocs } from "firebase/firestore";
import { db } from "../../_config/firebaseConfig"; // Import your Firebase Firestore instance here
import { PageAndSearchArgs, PagedData } from "../../interfaces";
import Offers from "../../interfaces/Offers/Offers";

interface OfferService {
  getOfferByPage: (args: PageAndSearchArgs) => Promise<PagedData<Offers>>;
  getOffers: () => Promise<any>;
}
const offerRef = collection(db, "Offers");
const service: OfferService = {
  getOfferByPage: async ({ search, pageNo }: PageAndSearchArgs) => {
    const pageSize = 13;
    try {
      const querySnapshot = await getDocs(offerRef);

      const offers: Offers[] = [];

      querySnapshot.forEach((doc) => {
        const offerData = doc.data();
        offerData.id = doc.id;

        offers.push({
          id: offerData.id,
          offerName: offerData.offerName,
          discountAmount: offerData.discountAmount,
          includedPackages: offerData.includedPackages,
          expirationDate: offerData.expirationDate,
          status: offerData.status,
          image_url: offerData.image_url,
        });
      });
      return {
        pageNo: pageNo,
        pageSize: pageSize,
        pageCount: 1,
        itemCount: offers.length,
        data: offers,
      };
    } catch (error) {
      console.error("Firebase error:", error);
      throw error;
    }
  },
  getOffers: async () => {
    try {
      const querySnapshot = await getDocs(offerRef);

      const offers: Offers[] = [];

      querySnapshot.forEach((doc) => {
        const offerData = doc.data();
        offerData.id = doc.id;

        offers.push({
          id: offerData.id,
          offerName: offerData.offerName,
          discountAmount: offerData.discountAmount,
          includedPackages: offerData.includedPackages,
          expirationDate: offerData.expirationDate,
          status: offerData.status,
          image_url: offerData.image_url,
        });
      });
      return offers;
    } catch (error) {
      console.error("Firebase error:", error);
      throw error;
    }
  },
};

export default service;
