enum StateTopicEnum {
  Alert = "_alert",
  LibraryPage = "_libraryPage",
  LibrarySearch = "_librarySearch",
  LibrarySearchAndPage = "_librarySearchAndPage",
  Modal = "_modal",
  LargeModal = "_largeModal",
  NavigationContext = "_navigationContext",
  User = "_user",
  Site = "_site",
  ActiveModule = "_activeModule",
  CurrentItem = "_currentItem",
  ModalEvent = "_modalEvent",
  ModelSaveEvent = "_modelSaveEvent",
  ModelUpdateEvent = "_modelUpdateEvent",
  FilterCount = "_filterCount",
  UpdateDimension = "_treeEvent",
  ReloadUpdate = "_reloadEvent",
  FilterUpdate = "_updateFilter",
  UpdateStream = "UpdateStream",
  SaveEvent = "_saveEvent",
}

export default StateTopicEnum;
