/* eslint-disable react-hooks/exhaustive-deps */

import Banners from "../../components/banners/Banners";
import SideContactFormComponent from "../../components/contact/SideContactForm";
import Footer from "../../components/home/footer";
import Navbar from "../../components/home/navbar";

export default function ContactPage() {
  return (
    <>
      <Navbar transparent={true} />
      <main>
        <Banners
          home={false}
          hasButton={false}
          title="Contact Us"
          imageUrl="./banners/banner2.jpg"
        />
        <section className="relative pt-16 pb-32">
          <div className="flex flex-wrap items-center mt-4 ">
            <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
              <h3 className="text-3xl mb-2 font-semibold leading-normal">
                Explore Your Next Adventure
              </h3>
              <h3 className="text-lg mb-2 font-normal leading-normal text-desert-200">
                - &ldquo;Every Journey Start by Seeking Direction&rdquo;
              </h3>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                Ready to take the first step? Contact us to start planning your
                unforgettable journey!
              </p>
              <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                Our passion for exploration and deep love for Namibia inspire us
                to curate exceptional tours that showcase the rich diversity of
                this beautiful country. From the vast deserts to lush national
                parks, we invite you to discover the wonders of Namibia through
                our expertly crafted journeys.
              </p>
            </div>

            <div className="w-full md:w-4/12 px-4 mr-auto ml-auto mt-24">
              {/* <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-tahiti-700"> */}
              <SideContactFormComponent />
              {/* </div> */}
            </div>
          </div>
        </section>

        <section className="pb-24 pt-24">
          <div className="bg-gray-100">
            <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
              <h2 className="text-3xl leading-9 font-extrabold text-desert-200">
                Frequently Asked Questions
              </h2>
              <div className="mt-6 border-t border-tahiti-500 pt-10">
                <div className="text-left">
                  <h3 className="text-xl leading-7 font-semibold text-gray-900">
                    How does your tour service work?
                  </h3>
                  <p className="mt-3 text-base leading-6 text-gray-500">
                    Our tour service offers carefully curated experiences for
                    adventurers like you. Join our expert guides on thrilling
                    journeys to breathtaking destinations. Simply choose your
                    preferred tour, book your spot, and get ready for an
                    unforgettable adventure!
                  </p>
                </div>

                <div className="mt-10 text-left">
                  <h3 className="text-xl leading-7 font-semibold text-gray-900">
                    Are refreshments provided during the tours?
                  </h3>
                  <p className="mt-3 text-base leading-6 text-gray-500">
                    Yes, we strive to make your experience enjoyable and
                    comfortable. Refreshments, including water and light snacks,
                    will be provided during the tour. Stay energized and
                    hydrated as you explore alongside fellow travelers.
                  </p>
                </div>

                <div className="mt-10 text-left">
                  <h3 className="text-xl leading-7 font-semibold text-gray-900">
                    What should I bring on the tour?
                  </h3>
                  <p className="mt-3 text-base leading-6 text-gray-500">
                    When embarking on our tours, we recommend bringing
                    essentials such as comfortable clothing, sturdy footwear, a
                    hat, sunscreen, sunglasses, and a camera to capture
                    memorable moments. Check the specific tour details for any
                    additional recommendations.
                  </p>
                </div>
                <div className="mt-10 text-left">
                  <h3 className="text-xl leading-7 font-semibold text-gray-900">
                    How do I book a tour?
                  </h3>
                  <p className="mt-3 text-base leading-6 text-gray-500">
                    Booking a tour is simple! Visit our website, browse through
                    our exciting tour options, and select the one that suits
                    your interests. Click on the &quot;Book Now&quot; button,
                    follow the prompts to complete the booking process, and
                    you&apos;re all set for your adventure!
                  </p>
                </div>

                <div className="mt-10 text-left">
                  <h3 className="text-xl leading-7 font-semibold text-gray-900">
                    Do you offer group tours?
                  </h3>
                  <p className="mt-3 text-base leading-6 text-gray-500">
                    Yes, we offer group tours for a more social and shared
                    experience. If you&apos;re traveling with friends or family,
                    group tours are a great way to explore together. Check our
                    tour details for information on group sizes and any special
                    group discounts available.
                  </p>
                </div>

                <div className="mt-10 text-left">
                  <h3 className="text-xl leading-7 font-semibold text-gray-900">
                    What safety measures are in place during the tours?
                  </h3>
                  <p className="mt-3 text-base leading-6 text-gray-500">
                    Your safety is our top priority. We adhere to strict safety
                    standards and guidelines. Our expert guides are trained to
                    ensure a secure and enjoyable experience. Additionally, we
                    provide safety gear, and our tours are designed to minimize
                    risks while maximizing fun.
                  </p>
                </div>

                <div className="mt-10 text-left">
                  <h3 className="text-xl leading-7 font-semibold text-gray-900">
                    Can I customize a tour based on my preferences?
                  </h3>
                  <p className="mt-3 text-base leading-6 text-gray-500">
                    Absolutely! We understand that every traveler is unique.
                    Contact our customer support team to discuss your
                    preferences, and we&apos;ll do our best to tailor a tour
                    that meets your specific needs and interests.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Trusted By Section */}
        <section className="relative bg-desert-200 pt-8 pb-20 -mt-8 shadow-lg rounded-lg">
          <div className="container mx-auto px-4 pt-0">
            <div className="bg-gray-100 py-32 sm:pt-8 shadow-lg rounded-lg pt-8">
              <h1 className="text-center text-3xl font-semibold leading-6 text-gray-900">
                Our Recommendations for your Journey Ahead:
              </h1>
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto pt-8 mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                  <img
                    className="col-span-2 max-h-12 w-full object-contain lg:col-span-1 transform hover:scale-105 hover:-translate-y-2 transition-transform duration-300 ease-in-out hover:cursor-pointer"
                    src="/img/NewLog250.png"
                    alt="Crossroads Car Hire"
                    width={158}
                    height={48}
                    onClick={() => {
                      window.location.href = "https://crossroadscarhire.com/";
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
