import { collection, setDoc, doc } from "firebase/firestore";
import { db } from "../../_config/firebaseConfig"; // Import your Firebase Firestore instance here
import BookForm from "../../interfaces/BookForm";

interface BookingService {
  postBooking: (data: BookForm) => Promise<any>;
  verifyRecaptcha: (captchaValue: string) => Promise<any>;
}

const service: BookingService = {
  postBooking: async (data: BookForm) => {
    try {
      const bookingRef = collection(db, "Bookings");

      // Use setDoc and provide the desired ID in the second parameter
      await setDoc(doc(bookingRef, data.id), data);

      // Return the success and ID information
      return { success: true, id: data.id };
    } catch (error) {
      console.error("Firebase error:", error);
      return { success: false, error: error };
    }
  },
  verifyRecaptcha: async (captchaValue: string) => {
    try {
      const SITE_SECRET = process.env.SITE_SECRET;

      const verificationURL = `https://www.google.com/recaptcha/api/siteverify?secret=${SITE_SECRET}&response=${captchaValue}`;

      // Make a request to the ReCAPTCHA verification endpoint
      const response = await fetch(verificationURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      // Parse the JSON response
      const result = await response.json();

      // Return the success and verification result
      return { success: result.success, result };
    } catch (error) {
      console.error("Error verifying ReCAPTCHA:", error);
      return { success: false, error: error };
    }
  },
};

export default service;
