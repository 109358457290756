import Banners from "../../components/banners/Banners";
import Footer from "../../components/home/footer";
import Navbar from "../../components/home/navbar";

export default function TermsAndConditionsPage() {
  return (
    <>
      <Navbar transparent />
      <main>
        <Banners
          home={false}
          hasButton={false}
          title="Terms and Conditions"
          imageUrl="./banners/banner5.jpg"
        />

        <section className="relative pt-16 pb-32">
          <div className="bg-white min-h-screen py-8">
            <div className="container mx-auto px-4">
              <h1 className="text-3xl font-semibold text-center my-4">
                Terms and Conditions
              </h1>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <p>
                  Welcome to Sandwich & Namibia Travel and Tours
                  (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;). By
                  accessing and using our services, you agree to comply with and
                  be bound by the following terms and conditions.
                </p>
                <p>
                  Please read these terms carefully before using our services.
                  If you do not agree with any part of these terms, do not
                  access or use our services.
                </p>

                <h2 className="text-xl font-semibold my-4">
                  1. Booking and Reservations
                </h2>
                <p>
                  1.1 All bookings and reservations made through our website or
                  third-party platforms are subject to availability and
                  confirmation. We reserve the right to cancel or modify
                  bookings if necessary.
                </p>
                <p>
                  1.2 Changes to bookings, including date changes, may be
                  accommodated based on availability and our discretion.
                </p>

                <h2 className="text-xl font-semibold my-4">
                  2. Payment and Cancellation
                </h2>
                <p>
                  2.1 Payments must be made in accordance with the provided
                  instructions. Failure to make timely payments may result in
                  booking cancellations.
                </p>
                <p>
                  2.2 Cancellation policies vary by tour or service and are
                  outlined in the specific tour descriptions. Refund eligibility
                  is subject to these policies.
                </p>

                <h2 className="text-xl font-semibold my-4">
                  3. Travel Insurance
                </h2>
                <p>
                  3.1 We strongly recommend that all travelers purchase
                  comprehensive travel insurance to cover unforeseen
                  circumstances, cancellations, and medical emergencies.
                </p>
                <p>
                  3.2 It is the responsibility of the traveler to ensure that
                  their insurance coverage is adequate for the duration and
                  nature of the booked tour.
                </p>

                <h2 className="text-xl font-semibold my-4">
                  4. Code of Conduct
                </h2>
                <p>
                  4.1 Participants are expected to behave responsibly and
                  respectfully during tours. We reserve the right to refuse
                  service or terminate participation for disruptive behavior.
                </p>
                <p>
                  4.2 Participants are required to follow safety guidelines
                  provided by our guides and staff during tours.
                </p>

                <h2 className="text-xl font-semibold my-4">
                  5. Privacy Policy
                </h2>
                <p>
                  5.1 We collect and process personal information in accordance
                  with our Privacy Policy. By using our services, you consent to
                  the collection and use of your information as outlined in the
                  Privacy Policy.
                </p>

                <h2 className="text-xl font-semibold my-4">6. Force Majeure</h2>
                <p>
                  6.1 We are not liable for delays, cancellations, or changes to
                  services caused by circumstances beyond our control, including
                  but not limited to natural disasters, weather conditions, and
                  government actions.
                </p>

                {/* Add more sections and details as needed */}
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
