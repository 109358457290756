import { useEffect, useState } from "react";
import routes from "../../_config/routes";
import { Link } from "react-router-dom";

export default function Navbar(props: any) {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [scrollPastPoint, setScrollPastPoint] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY > 750 && !scrollPastPoint) {
        setScrollPastPoint(true);
      }

      if (scrollY <= 750 && scrollPastPoint) {
        setScrollPastPoint(false);
      }

      setIsSticky(scrollY > 750);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPastPoint]);

  const navbarClasses = `top-0 fixed absolute z-10 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg transition-all duration-300 ease-in-out ${
    isSticky ? "sticky bg-white text-black" : ""
  }`;
  const textAndIconColor = "text-black/70";

  return (
    <>
      <nav className={navbarClasses}>
        <div className="container px-0 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <a
              href={"/"}
              className={`text-3xl font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase ${textAndIconColor}`}
            >
              <img
                alt="..."
                src="/img/sandwichLogo.png"
                className={`${
                  isSticky
                    ? "w-6/12 align-middle rounded-lg max-w-[200px] h-auto"
                    : "w-10/12 align-middle rounded-lg max-w-[200px] h-auto"
                }`}
              />
            </a>
            <button
              className="lg:hidden text-3xl cursor-pointer focus:outline-none m-6"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              ☰
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none" +
              (navbarOpen
                ? " block rounded shadow-lg align-middle text-center items-center ml-0 mr-0"
                : " hidden")
            }
            id="example-navbar-warning"
          >
            {" "}
            <ul
              className={`flex flex-col lg:flex-row list-none lg:ml-auto ${
                navbarOpen
                  ? "justify-center ml-8 text-center"
                  : "flex flex-col lg:flex-row list-none lg:ml-auto"
              }`}
            >
              <li className="flex items-center">
                <Link
                  className={`text-2xl font-bold leading-6 inline-block mr-6 py-2 whitespace-nowrap uppercase lg:hover:text-desert-200 hover:text-desert-200  ${textAndIconColor}`}
                  to={"/"}
                >
                  Home
                </Link>
              </li>
              <li className="flex items-center">
                <Link
                  className={`text-2xl font-bold leading-relaxed inline-block mr-6 py-2 whitespace-nowrap uppercase lg:hover:text-desert-200 hover:text-desert-200  ${textAndIconColor}`}
                  to={routes.about.raw}
                >
                  About Us
                </Link>
              </li>
              <li className="relative flex items-center group">
                <Link
                  className={`text-2xl font-bold leading-relaxed inline-block mr-6 py-2 whitespace-nowrap uppercase lg:hover:text-desert-200 hover:text-desert-200  ${textAndIconColor}`}
                  to={routes.tours.raw}
                >
                  Experiences
                </Link>
              </li>
              {/* <li className="relative flex items-center group">
                <Link
                  className={`text-2xl font-bold leading-relaxed inline-block mr-6 py-2 whitespace-nowrap uppercase lg:hover:text-desert-200 hover:text-desert-200  ${textAndIconColor}`}
                  to={routes.specials.raw}
                >
                  Specials
                </Link>
              </li> */}
              <li className="flex items-center">
                <Link
                  className={`text-2xl font-bold leading-relaxed inline-block mr-6 py-2 whitespace-nowrap uppercase lg:hover:text-desert-200 hover:text-desert-200  ${textAndIconColor}`}
                  to={routes.gallery.raw}
                >
                  Gallery
                </Link>
              </li>
              <li className="flex items-center">
                <Link
                  className={`text-2xl font-bold leading-relaxed inline-block mr-6 py-2 whitespace-nowrap uppercase lg:hover:text-desert-200 hover:text-desert-200  ${textAndIconColor}`}
                  to={routes.contact.raw}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
