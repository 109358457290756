/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-redundant-roles */
import { useState, useEffect } from "react";
import Banners from "../../../components/banners/Banners";
import BookingForm from "../../../components/booking/bookingForm";
import Footer from "../../../components/home/footer";
import Navbar from "../../../components/home/navbar";
import PackageItem from "../../../interfaces/PackageItem";
import service from "../../../services/Package/packageService";
import { useParams } from "react-router-dom";

export default function TourPage() {
  const { tourId } = useParams<any>();
  const [packageItem, setPackages] = useState<PackageItem>();
  const [isLoading, setLoading] = useState(true);
  const downloadURL = packageItem?.iternerary?.toString();
  const [, setModalIsOpen] = useState(false);
  const openModal = () => {
    setModalIsOpen(true);
    return (
      <>
        <img
          alt="..."
          src={`${packageItem?.image_url}`}
          className="max-w-full max-h-full"
        />
        <button onClick={closeModal}>Close Modal</button>
      </>
    );
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleDownload = () => {
    if (downloadURL) {
      // Open the download link in a new window
      window.open(downloadURL, "_blank");
    }
  };

  useEffect(() => {
    const fetchPackageItem = async () => {
      try {
        const packageData: PackageItem = await service.getPackageSingle(
          tourId as string
        );
        setPackages(packageData);
      } catch (error) {
        console.error("Error fetching packages:", error);
      }
    };

    fetchPackageItem();
  }, [tourId]);

  useEffect(() => {
    const delay = 2000;
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, delay);
  }, [packageItem]);

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <img
          src="../../icons/jeep.gif"
          alt="Loading"
          width={"50px"}
          height={"50px"}
        />
      </div>
    );
  }
  return (
    <>
      {!isLoading && packageItem ? (
        <>
          <Navbar transparent />
          <main>
            <Banners
              home={false}
              hasButton={false}
              pathColor="text-gray-100"
              strokeColor="text-desert-200"
              title={packageItem?.title}
              imageUrl={`${packageItem?.image_url}`}
            />

            <section className="relative pt-16 pb-16 bg-blueGray-100">
              <div className="flex flex-wrap items-center mt-4 ">
                <div className="w-full md:w-4/12 px-4 mr-24 ml-auto">
                  <h3 className="text-3xl mb-2 font-semibold leading-normal">
                    {packageItem?.title} Tour
                  </h3>
                  <h6 className="text-lg mb-2 leading-normal text-tahiti-200">
                    {packageItem?.subTitle}
                  </h6>
                  <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                    {packageItem?.description}
                  </p>
                  <p className="text-xl font-light leading-relaxed mt-4 mb-4 text-blueGray-900">
                    Price:{" "}
                    <span className="text-2xl font-light leading-relaxed ml-8 mb-0 text-desert-200">
                      {" "}
                      {packageItem.price?.includes("Request")
                        ? packageItem.price
                        : "N$ " + packageItem.price + ".00"}
                    </span>
                  </p>
                </div>

                <div className="w-full md:w-5/12 px-4 mr-24 ml-0">
                  <div className="relative flex flex-col min-w-0 break-words bg-white w-250-px mb-6 shadow-lg rounded-lg">
                    <img
                      alt="..."
                      src={`${packageItem?.image_url}`}
                      className="h-full align-middle rounded-lg w-250-px"
                      onClick={openModal}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="relative pb-12 ">
              <div
                className="container mx-auto  bg-white p-6 shadow-lg rounded-lg mt-24"
                style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}
              >
                <div className="mx-auto">
                  <h3 className="text-xl font-semibold leading-7 text-gray-900">
                    PackageItem Information
                  </h3>
                  <p className="mt-1 max-w-2xl text-md leading-6 text-gray-500">
                    Everything Included in this Package:
                  </p>
                </div>
                <div className="mt-6 border-t border-gray-100">
                  <dl className="divide-y  divide-gray-200">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-lg font-bold leading-6 text-gray-900">
                        Duration
                      </dt>
                      <dd className="mt-1 text-lg leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        Full Day
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-lg font-bold leading-6 text-gray-900">
                        Pickup
                      </dt>
                      <dd className="mt-1 text-md leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {packageItem.pickup}
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-lg font-bold leading-6 text-gray-900">
                        Drop-Off
                      </dt>
                      <dd className="mt-1 text-md leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {packageItem.dropOff}
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-lg font-bold leading-6 text-gray-900">
                        Start Location
                      </dt>
                      <dd className="mt-1 text-md leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {packageItem.startLocation}
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-lg font-bold leading-6 text-gray-900">
                        Includes
                      </dt>
                      <dd className="mt-1 text-md leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <ul className="list-disc list-inside">
                          {Array.isArray(packageItem?.included) &&
                            packageItem.included.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                        </ul>
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-lg font-bold leading-6 text-gray-900">
                        What to Pack
                      </dt>
                      <dd className="mt-1 text-md leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <ul className="list-disc list-inside">
                          {Array.isArray(packageItem?.pack) &&
                            packageItem.pack.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                        </ul>
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-lg font-bold leading-6 text-gray-900">
                        Price
                      </dt>
                      <dd className="mt-1 text-md leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {packageItem.price?.includes("Request")
                          ? packageItem.price
                          : "N$ " + packageItem.price + ".00"}
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-lg font-bold leading-6 text-gray-900">
                        Conditions
                      </dt>
                      <dd className="mt-1 text-md leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <ul className="list-disc list-inside">
                          {packageItem?.conditions?.split("–")?.map(
                            (item, index) =>
                              // Only render the list item if it has text
                              item.trim() && <li key={index}>{item}</li>
                          )}
                        </ul>
                      </dd>
                    </div>

                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-lg font-bold leading-6 text-gray-900">
                        Itenanery
                      </dt>
                      <dd className="mt-2 text-md text-gray-900 sm:col-span-2 sm:mt-0">
                        <ul
                          role="list"
                          className="divide-y divide-gray-100 rounded-md border border-gray-200"
                        >
                          <li className="flex items-center justify-between py-4 pl-4 pr-5 text-md leading-6">
                            <div className="flex w-0 flex-1 items-center">
                              <svg
                                className="h-5 w-5 flex-shrink-0 text-gray-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                <span className="truncate font-medium">
                                  <b>Iternerary:</b> {packageItem.title}
                                </span>
                              </div>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                              <a
                                // href={downloadURL}
                                className="font-medium text-black hover:text-tahiti-200 cursor-pointer"
                                onClick={handleDownload}
                                download
                              >
                                Download
                              </a>
                            </div>
                          </li>
                        </ul>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </section>
            <section>
              <BookingForm />
            </section>
          </main>
          <Footer />
        </>
      ) : null}
    </>
  );
}
