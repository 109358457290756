"use client";
import React, { useState } from "react";

const CookiesOverlay = ({ onAccept, onDecline }: any) => {
  const [isVisible, setIsVisible] = useState(true);

  const acceptCookies = () => {
    setIsVisible(false);
    onAccept();
  };

  const declineCookies = () => {
    setIsVisible(false);
    onDecline();
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="fixed bottom-0 w-full z-10">
      <div className="bg-white border-t border-gray-200 p-4 flex justify-between items-center shadow-md">
        <div className="text-sm text-gray-500">
          We use cookies to improve your experience. By using our site, you
          consent to cookies.
        </div>
        <div>
          <button
            onClick={acceptCookies}
            className="bg-tahiti-200 text-white py-2 px-4 rounded mr-4"
          >
            Accept
          </button>
          <button
            onClick={declineCookies}
            className="bg-white text-black hover:underline"
          >
            Decline
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookiesOverlay;
