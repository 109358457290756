import { httpsCallable } from "firebase/functions";
import { functions } from "../../_config/firebaseConfig";

interface GalleryService {
  fetchFacebookGallery: () => Promise<any>;
}

const service: GalleryService = {
  fetchFacebookGallery: async () => {
    try {
      // Call the new Firebase Function to fetch Facebook gallery data
      const result: any = await httpsCallable(
        functions,
        "getFacebookGallery"
      )();
      return result.data;
    } catch (error) {
      console.error("Error fetching Facebook gallery:", error);
      throw error;
    }
  },
};

export default service;
