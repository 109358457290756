/* eslint-disable react-hooks/exhaustive-deps */
"use client";
import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import ContactForm from "../../interfaces/ContactForm";
import { useStateReducer } from "../../hooks";
import service from "../../services/emailing/emailingService";
import { functions } from "../../_config/firebaseConfig";
import { useNavigate } from "react-router-dom";
import bookingService from "../../services/booking/bookingService";
import ReCAPTCHA from "react-google-recaptcha";
import routes from "../../_config/routes";
interface ContactDataProps {
  contactForm?: ContactForm;
}
interface State {
  name?: string;
  email?: string;
  message?: string;
  errors: {
    name?: string;
    email?: string;
    message?: string;
    recaptcha?: string;
  };
  loading: boolean;
}
export default function ContactFormComponent({
  contactForm,
}: ContactDataProps) {
  const [state, setState] = useStateReducer<State>({
    name: contactForm?.name,
    email: contactForm?.email,
    message: contactForm?.message,
    errors: {},
    loading: false,
  });
  const [recaptchaResponse, setRecaptchaResponse] = useState<string | null>(
    null
  );
  const navigate = useNavigate();
  const siteKey = process.env.REACT_APP_SITE_KEY ?? "";
  const handleChange = (prop: string, value: any) => {
    setState({ ...state, [prop]: value });
  };
  const validateForm = () => {
    const errors: { [key: string]: string } = {};

    if (!state.name) {
      errors.name = "Name is required";
    }

    if (!state.email) {
      errors.email = "Email is required";
    } else if (!isValidEmail(state.email)) {
      errors.email = "Invalid email address";
    }
    if (!state.message) {
      errors.message = "Message is required";
    }
    if (!recaptchaResponse) {
      errors.recaptcha = "Please verify that you're not a robot";
    }

    setState({ ...state, errors });
    return Object.keys(errors).length === 0;
  };

  const isValidEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };
  const handleRecaptchaChange = (response: string | null) => {
    setRecaptchaResponse(response);
    state.errors.recaptcha = "";
  };
  const handleSubmit = async (e?: any) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setState({ ...state, loading: true });
        const response = await service.sendEmail(state as ContactForm); // Pass state as data
        const sendEmailFunction = httpsCallable(functions, "/sendEmail");
        await sendEmailFunction(state as ContactForm);
        await bookingService.verifyRecaptcha(recaptchaResponse || "");

        if (response?.success === true) {
          navigate(routes.success.raw);
        } else {
          alert("Failed to submit contact form. Please try again.");
        }
        return response;
      } catch (error) {
        console.error("Error sending Message:", error);
      } finally {
        setState({ ...state, loading: false }); // Reset loading state in case of error
      }
    }
  };

  useEffect(() => {
    setState({ ...state, errors: {} });
  }, [state.name, state.email, state.message]);
  return (
    <>
      <main>
        <section className="pb-20 relative block bg-white backdrop-blur-xl shadow-xl rounded-lg shadow-desert-200">
          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4 m-8">
                <h2 className="text-4xl font-semibold text-desert-200">
                  Explore Your Next Adventure
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-tahiti-800">
                  Ready to take the first step? Contact us to start planning
                  your unforgettable journey!
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="relative block py-24 lg:pt-0 bg-white">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-100">
                  <div className="flex-auto p-5 lg:p-10">
                    <h4 className="text-2xl font-semibold">
                      Let&apos;s Connect and Plan Together!
                    </h4>
                    <p className="leading-relaxed mt-1 mb-4 text-blueGray-600">
                      Complete the form below, and we&apos;ll get back to you
                      within 24 hours.
                    </p>
                    <div className="relative w-full mb-3 mt-8">
                      <label
                        className="block uppercase text-blueGray-900 text-xs font-bold mb-2"
                        htmlFor="full-name"
                      >
                        Full Name
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-600 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Full Name"
                        onChange={(e) => handleChange("name", e.target.value)}
                      />
                      {state.errors.name && (
                        <span className="text-red-600">
                          {state.errors.name}
                        </span>
                      )}
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-900 text-xs font-bold mb-2"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="border-0 px-3 py-3 placeholder-blueGray-600 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                        onChange={(e) => handleChange("email", e.target.value)}
                      />
                      {state.errors.email && (
                        <span className="text-red-600">
                          {state.errors.email}
                        </span>
                      )}
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-900 text-xs font-bold mb-2"
                        htmlFor="message"
                      >
                        Message
                      </label>
                      <textarea
                        rows={4}
                        cols={80}
                        className="border-0 px-3 py-3 placeholder-blueGray-600 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Type a message..."
                        onChange={(e) =>
                          handleChange("message", e.target.value)
                        }
                      />
                      {state.errors.message && (
                        <span className="text-red-600">
                          {state.errors.message}
                        </span>
                      )}
                    </div>
                    <div className="relative w-full mb-3">
                      {siteKey && (
                        <ReCAPTCHA
                          sitekey={siteKey}
                          onChange={handleRecaptchaChange}
                        />
                      )}
                      {state.errors.recaptcha && (
                        <span className="text-red-600">
                          {state.errors.recaptcha}
                        </span>
                      )}
                    </div>
                    <div className="text-center mt-6">
                      <button
                        className="bg-desert-200 text-gray-100 active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        disabled={state.loading}
                        onClick={handleSubmit}
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
