import {
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  addDoc,
  deleteDoc,
  query,
  where,
  limit,
  orderBy,
} from "firebase/firestore";
import { db } from "../../_config/firebaseConfig"; // Import your Firebase Firestore instance here
import { PageAndSearchArgs, PagedData } from "../../interfaces";
import { StateTopicEnum } from "../../enums";
import { publish } from "../../hooks";
import PackageItem from "../../interfaces/PackageItem";

interface PackageService {
  getPackageByPage: (
    args: PageAndSearchArgs
  ) => Promise<PagedData<PackageItem>>;
  getPackages: () => Promise<any>;
  getPackageSingle: (id: string) => Promise<any>;
  createPackage: (data: PackageItem) => Promise<any>;
  updatePackage: (id: string, data: PackageItem) => Promise<any>;
  deletePackage: (id?: string) => Promise<void>;

  getPopularPackages: () => Promise<any>;
}
const packageRef = collection(db, "Packages");
const service: PackageService = {
  getPackageByPage: async ({ search, pageNo }: PageAndSearchArgs) => {
    const pageSize = 13;
    try {
      const q = query(packageRef, orderBy("title"));
      const querySnapshot = await getDocs(q);
      const packages: PackageItem[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data();
        packageData.id = doc.id;

        packages.push({
          id: packageData.id,
          description: packageData.description,
          image_url: packageData.image_url,
          iternerary: packageData.iternerary,
          duration: packageData.duration,
          tag: packageData.tag,
          included: packageData.included,
          price: packageData.price,
        });
      });
      return {
        pageNo: pageNo,
        pageSize: pageSize,
        pageCount: 1,
        itemCount: packages.length,
        data: packages,
      };
    } catch (error) {
      console.error("Firebase error:", error);
      throw error;
    }
  },
  getPackages: async () => {
    try {
      const q = query(packageRef, orderBy("title"));
      const querySnapshot = await getDocs(q);

      const packages: PackageItem[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data();
        packageData.id = doc.id;

        packages.push({
          id: packageData.id,
          title: packageData.title,
          description: packageData.description,
          image_url: packageData.image_url,
          iternerary: packageData.iternerary,
          duration: packageData.duration,
          tag: packageData.tag,
          included: packageData.included,
          price: packageData.price,
        });
      });
      return packages as PackageItem[];
    } catch (error) {
      console.error("Firebase error:", error);
      throw error;
    }
  },
  getPopularPackages: async () => {
    try {
      // Assuming packageRef is a reference to your Firestore collection
      const querySnapshot = await getDocs(
        query(
          packageRef,
          orderBy("title"),
          where("tag", "==", "Popular"),
          limit(3)
        )
      );

      const packages: PackageItem[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data();
        packageData.id = doc.id;

        packages.push({
          id: packageData.id,
          title: packageData.title,
          description: packageData.description,
          image_url: packageData.image_url,
          iternerary: packageData.iternerary,
          duration: packageData.duration,
          tag: packageData.tag,
          included: packageData.included,
          price: packageData.price,
        });
      });

      return packages as PackageItem[];
    } catch (error) {
      console.error("Firebase error:", error);
      throw error;
    }
  },

  getPackageSingle: async (id: string) => {
    try {
      const packageRef = doc(db, "Packages", id);
      const docSnapshot = await getDoc(packageRef);

      if (docSnapshot.exists()) {
        const packageData = docSnapshot.data();
        packageData.id = docSnapshot.id;
        return packageData as PackageItem;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Firebase error:", error);
      throw error;
    }
  },
  createPackage: async (data: PackageItem) => {
    try {
      const docRef = await addDoc(packageRef, data);
      publish(StateTopicEnum.ReloadUpdate, null);
      return { success: true, id: docRef.id };
    } catch (error) {
      console.error("Firebase error:", error);
      return { success: false, error: error };
    }
  },

  updatePackage: async (id: string, data: PackageItem) => {
    try {
      const packageRef = doc(db, "Packages", id);
      await setDoc(packageRef, data);
      publish(StateTopicEnum.ReloadUpdate, null);
      return { success: true };
    } catch (error) {
      console.error("Firebase error:", error);
      return { success: false, error: error };
    }
  },
  deletePackage: async (id?: string): Promise<void> => {
    try {
      if (!id) {
        throw new Error("PackageItem ID is missing.");
      }

      const packageRef = doc(db, "Packages", id);

      await deleteDoc(packageRef);
    } catch (error) {
      console.error("Firebase error:", error);
    }
  },
};

export default service;
