import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/home/page";
import routes from "./_config/routes";
import AboutPage from "./pages/about/page";
import ToursPage from "./pages/tours/page";
import ContactPage from "./pages/contact/page";
import ReviewPage from "./pages/reviews/page";
import BookingPage from "./pages/booking/page";
import PrivacyPolicyPage from "./pages/privacy/page";
import TermsAndConditionsPage from "./pages/terms/page";
import GalleryPage from "./pages/gallery/page";
import TourPage from "./pages/tours/[id]/page";
import SuccessPage from "./pages/success/page";
import FourOFourPage from "./pages/404/page";
import OfferPage from "./pages/offers/page";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path={routes.home.raw} element={<HomePage />} />
      <Route path={routes.about.raw} element={<AboutPage />} />
      <Route path={routes.tours.raw} element={<ToursPage />} />
      <Route path={routes.specials.raw} element={<OfferPage />} />
      <Route path={routes.tourItem.raw} element={<TourPage />} />
      <Route path={routes.gallery.raw} element={<GalleryPage />} />
      <Route path={routes.contact.raw} element={<ContactPage />} />
      <Route path={routes.review.raw} element={<ReviewPage />} />
      <Route path={routes.booking.raw} element={<BookingPage />} />
      <Route path={routes.privacy.raw} element={<PrivacyPolicyPage />} />
      <Route path={routes.terms.raw} element={<TermsAndConditionsPage />} />
      <Route path={routes.success.raw} element={<SuccessPage />} />
      <Route path="*" element={<FourOFourPage />} />
    </Routes>
  );
}
