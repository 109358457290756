import Banners from "../../components/banners/Banners";
import Carousel from "../../components/home/carousel";
import Footer from "../../components/home/footer";
import Navbar from "../../components/home/navbar";

export default function AboutPage() {
  return (
    <>
      {/* <Helmet title="Welcome to Sandwich & Namibia Travel and Tours!" /> */}
      <Navbar transparent />
      <main>
        <Banners
          home={false}
          hasButton={false}
          title="About Us"
          imageUrl="./banners/banner9.jpg"
        />

        <section className="relative pt-16 pb-32">
          <div className="flex flex-wrap items-center mt-4 ">
            <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
              <h3 className="text-3xl mb-2 font-semibold leading-normal">
                Welcome to Sandwich & Namibia Travel and Tours!
              </h3>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                Embark on a journey like never before with Sandwich & Namibia
                Travels and Tours. As a family-owned company, we take pride in
                offering unique and unforgettable experiences across the
                breathtaking landscapes of Namibia.
              </p>
              <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                Our passion for exploration and deep love for Namibia inspire us
                to curate exceptional tours that showcase the rich diversity of
                this beautiful country. From the vast deserts to lush national
                parks, we invite you to discover the wonders of Namibia through
                our expertly crafted journeys.
              </p>
              <a href="/" className="font-bold text-tahiti-700 mt-8">
                Explore Namibia with Us
              </a>
            </div>

            <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
                <img
                  alt="..."
                  src="./banners/fortuner.jpg"
                  className="w-full align-middle rounded-lg"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="relative pt-16 pb-32">
          <div className="bg-gray-100 -mt-6 shadow-lg rounded-lg shadow-gray-300 rounded-t-lg">
            <div className="mx-auto flex items-center justify-center flex-col px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:flex-row lg:px-8">
              <div className="text-center lg:text-left lg:mr-16">
                <h2 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
                  Meet our Guides!
                </h2>

                <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                  <div className="border-t border-desert-200 pt-4">
                    <dt className="font-large text-2xl text-gray-900">
                      Leon van Rensburg
                    </dt>
                    <dd className="mt-2 text-md text-gray-500">
                      Leon, our seasoned guide, is your key to unlocking the
                      secrets of the desert's rich fauna and flora. With years
                      of experience, he navigates the wilderness with expertise,
                      making every tour an adventure to remember.
                    </dd>
                  </div>
                  <img
                    src="./img/profilePic.jpg"
                    alt="Leon"
                    className="rounded-lg bg-white"
                  />
                  <div className="border-t border-desert-200 pt-4">
                    <dt className="font-large text-2xl text-gray-900">
                      Koets van Rensburg
                    </dt>
                    <dd className="mt-2 text-md text-gray-500">
                      Meet Koets, the backbone of our team. As the
                      administrative genius, catering extraordinaire, and the
                      mastermind behind the scenes, Koets ensures that every
                      tour runs smoothly. Her dedication and attention to detail
                      make your experience seamless and unforgettable.
                    </dd>
                  </div>
                  <img
                    src="./banners/banner7.jpg"
                    alt="Koets"
                    className="rounded-lg bg-white"
                  />
                </dl>
              </div>
            </div>
          </div>
        </section>

        <section className="relative bg-white pt-20 pb-32 -mt-8 shadow-lg rounded-lg">
          <Carousel />
        </section>
      </main>
      <Footer />
    </>
  );
}
