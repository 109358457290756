import "react-datepicker/dist/react-datepicker.css";
import Banners from "../../components/banners/Banners";
import BookingForm from "../../components/booking/bookingForm";
import Footer from "../../components/home/footer";
import Navbar from "../../components/home/navbar";

export default function BookingPage() {
  return (
    <>
      {/* <Helmet title="Ready to Book Your Adventure?" /> */}
      <Navbar transparent sticky={false} />
      <main>
        <Banners
          home={false}
          hasButton={false}
          title="Book Now"
          imageUrl="./banners/banner5.jpg"
        />
        <BookingForm />
      </main>
      <Footer />
    </>
  );
}
