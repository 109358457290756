import { useState, useEffect } from "react";
import Banners from "../../components/banners/Banners";
import Footer from "../../components/home/footer";
import Navbar from "../../components/home/navbar";
import { useStateReducer } from "../../hooks";
import { Link } from "react-router-dom";
import service from "../../services/Offers/offerService";
import Offers from "../../interfaces/Offers/Offers";

interface State {
  offer?: Offers[] | null;
  offerId?: string;
  isSaveDisabled?: boolean;
}
export default function OfferPage() {
  const [state, setState] = useStateReducer<State>({ isSaveDisabled: true });
  const [isLoading, setIsLoading] = useState(true);

  const load = async () => {
    try {
      const result: Offers[] = await service.getOffers();
      setState({ ...state, offer: result });
    } catch (error) {
      // Handle errors
    }
  };

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    const delay = 2000;
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, [state.offer]);

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <img
          src="icons/jeep.gif"
          alt="Loading"
          width={"50px"}
          height={"50px"}
        />
      </div>
    );
  }
  return (
    <>
      {/* <Helmet title="We have the right Tours just for you!" /> */}
      {!isLoading && state.offer ? (
        <>
          <Navbar transparent />
          <main>
            <Banners
              home={false}
              hasButton={false}
              title="Special Offers"
              imageUrl="./banners/banner4.jpg"
            />

            <section className="bg-white mt-2 relative">
              {/* Hero Section */}
              {state.offer?.map((item, index) => (
                <div
                  key={index}
                  className="w-screen h-96 bg-center bg-cover relative mb-12"
                  style={{
                    backgroundImage: `url(${item.image_url})`, // Use the hero image URL from your data
                  }}
                >
                  <div className="container mx-auto px-4 h-full">
                    <div className="flex items-center justify-center h-full">
                      <div className="text-center text-white">
                        <h1 className="font-semibold text-6xl">
                          {item.offerName}
                        </h1>
                        <h1 className="font-semibold text-6xl text-red-500">
                          {item.discountAmount}%
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              {/* Packages Grid */}
              <div className="container mx-auto px-4 mb-24">
                <div className="flex flex-wrap">
                  {state.offer?.map((item, index) => (
                    <div className="w-full md:w-5/12 px-6" key={index}>
                      {item.includedPackages?.map((packageItem, index) => (
                        <Link
                          to={`/tours/${packageItem.id}`}
                          className="transform hover:scale-105 block rounded-lg p-2 transition-transform duration-300"
                        >
                          <div className="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded-lg">
                            <img
                              alt="..."
                              src={packageItem.image_url}
                              className="h-350-px align-middle rounded-lg transform hover:-translate-y-6 transition-transform duration-300"
                            />
                            <blockquote className="relative p-8 mb-4">
                              <svg
                                preserveAspectRatio="none"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 583 95"
                                className="absolute left-0 w-full block h-95-px -top-94-px"
                              >
                                <polygon
                                  points="-30,95 583,95 583,65"
                                  className="text-white fill-current"
                                ></polygon>
                              </svg>
                              <h4 className="text-md font-bold mt-2 text-black ">
                                {packageItem?.title}
                              </h4>
                              <p
                                className="text-md font-light mt-2 text-black overflow-hidden"
                                style={{
                                  display: "-webkit-box",
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                  WebkitLineClamp: 4,
                                  maxHeight: "6em", // You can adjust this based on your font size and line height
                                }}
                              >
                                {packageItem?.description}
                              </p>
                            </blockquote>
                          </div>
                        </Link>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </main>
          <Footer />
        </>
      ) : null}
    </>
  );
}
