import { useState, useEffect } from "react";
import Banners from "../../components/banners/Banners";
import Navbar from "../../components/home/navbar";
import GalleryCarousel from "../../components/home/galleryCarousel";
import Footer from "../../components/home/footer";
import service from "../../services/gallery/galleryService";
interface Photo {
  created_time: string;
  id: string;
}
interface Album {
  albumId: string;
  albumName: string;
  photos: Photo[];
}
export default function GalleryPage() {
  const [photos, setPhotos] = useState<Album[]>([]);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchGallery = async () => {
    try {
      setIsLoading(true);
      const result: any = await service.fetchFacebookGallery();
      if (result.success) {
        setPhotos(result.photos || []);
      } else {
        console.error("Error fetching gallery:", result.error);
      }
    } catch (error) {
      console.error("Error fetching gallery:", error);
    } finally {
      setIsLoading(false);
    }
  };
  // Fetch long-lived token function
  const fetchLongLivedToken = async () => {
    try {
      const shortLivedToken = process.env.REACT_APP_FACEBOOK_ACCESS_TOKEN ?? "";
      setAccessToken(shortLivedToken);
    } catch (error) {
      console.error("Error fetching long-lived token:", error);
    }
  };

  useEffect(() => {
    fetchLongLivedToken();
  }, []);

  useEffect(() => {
    if (accessToken) {
      fetchGallery();
    }
  }, [accessToken]);

  useEffect(() => {
    const preventRightClick = (event: any) => {
      event.preventDefault();
    };

    document.addEventListener("contextmenu", preventRightClick);
    return () => {
      document.removeEventListener("contextmenu", preventRightClick);
    };
  }, []);

  return (
    <>
      <Navbar transparent />
      <main>
        <Banners
          home={false}
          hasButton={false}
          title="Gallery"
          imageUrl="./banners/banner5.jpg"
        />
        <section className="pt-20 pb-20 ml-10 mr-10 md:ml-20 md-mr-20">
          {!isLoading && photos ? (
            <div>
              {(photos as Album[]).map((album: Album, index) =>
                // Check if the album has photos before rendering
                album.photos.length > 0 ? (
                  <div key={index} className="mb-8 text-center">
                    <h2 className="text-3xl font-bold mb-4 text-desert-200">
                      {album.albumName}
                    </h2>
                    <GalleryCarousel
                      photos={album.photos}
                      access_token={accessToken}
                    />
                  </div>
                ) : (
                  <div key={index} className="mb-24 text-center">
                    <h2
                      className="text-3xl font-bold mb-4 text-desert-200 hover:cursor-pointer"
                      onClick={() =>
                        window.open(
                          "https://www.facebook.com/sandwichnam/photos_albums"
                        )
                      }
                    >
                      {album.albumName}
                    </h2>
                  </div>
                )
              )}
            </div>
          ) : (
            <div className="h-screen flex items-center justify-center">
              <img
                src="icons/jeep.gif"
                alt="Loading"
                width={"80px"}
                height={"80px"}
              />
            </div>
          )}
        </section>
      </main>
      <Footer />
    </>
  );
}
