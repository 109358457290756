// Import the functions you need from the SDKs you need
import {initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyBCM2oMoJK7bchgOtw-OKmfNBis2z8-BBM",
  authDomain: "sandwichtours.firebaseapp.com",
  projectId: "sandwichtours",
  storageBucket: "sandwichtours.appspot.com",
  messagingSenderId: "425699746783",
  appId: "1:425699746783:web:df86d5749b717d34991a6a",
  measurementId: "G-10SJKV2L4L"
};

 export function getFirebaseConfig() {
    if (!firebaseConfig || !firebaseConfig.apiKey) {
      throw new Error('No Firebase configuration object provided.' + '\n' +
      'Add your web app\'s configuration object to firebase-config.ts');
    } else {
      return firebaseConfig;
    }
  } 

const app = initializeApp(firebaseConfig); // Initialize Firebase app here
const auth = getAuth(app);
const db = getFirestore(app)
const functions = getFunctions(app);
const storage = getStorage(app);

export { db, auth, app as firebaseApp, functions, storage }; // Export Firebase app