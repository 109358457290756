import React from "react";
import { Link } from "react-router-dom";
import FacebookIcon from "../icons/facebook-f.svg"; // Update the import path
import InstagramIcon from "../icons/instagram.svg"; // Update the import path
import WhatsappIcon from "../icons/whatsapp.svg"; // Update the import path
import MailIcon from "../icons/email.svg";

export default function Footer() {
  return (
    <>
      <footer className="relative bg-gray-100 pt-8 pb-6">
        <div
          className="bottom-auto top-2 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            {/* Grey line alongside the curves */}
            <path
              className="text-blueGray-200 stroke-current"
              strokeWidth="4"
              fill="none"
              d="M1380,0 Q1300,10 1400,30 Q1500,70 1600,20 Q1700,50 1800,20 Q1900,60 2000,40 Q2100,50 2200,50 Q2300,70 2400,20 Q2500,40 2550,40 Q2600,40 2600,100"
            ></path>

            {/* First curve */}
            <path
              className="text-gray-100 fill-current"
              d="M1380,0 Q1300,10 1400,30 Q1500,70 1600,20 Q1700,50 1800,20 Q1900,60 2000,40 Q2100,50 2200,50 Q2300,70 2400,20 Q2500,40 2550,40 Q2600,40 2600,100 L100,90"
            ></path>
          </svg>
        </div>
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap text-center lg:text-left">
            <div className="w-full lg:w-6/12 px-4">
              <h4 className="text-3xl font-semibold text-desert-200">
                Lets keep in touch!
              </h4>
              <h5 className="text-lg mt-0 mb-2 text-blueGray-600">
                Find us on any of these platforms.
              </h5>
              <div className="mt-6 lg:mb-0 mb-6">
                <button
                  className="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 transform hover:scale-105 hover:-translate-y-2 transition-transform duration-300 ease-in-out"
                  type="button"
                  onClick={() => {
                    window.open("https://www.facebook.com/sandwichnam");
                  }}
                >
                  <img
                    src={FacebookIcon}
                    alt="Facebook"
                    className="w-6 h-6 ml-2 mr-2"
                  />
                </button>
                <button
                  className="bg-white text-orange-800 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 transform hover:scale-105 hover:-translate-y-2 transition-transform duration-300 ease-in-out"
                  type="button"
                  onClick={() => {
                    window.open(
                      "https://www.instagram.com/sandwichnamibiatraveltours/"
                    );
                  }}
                >
                  <img
                    src={InstagramIcon}
                    alt="Facebook"
                    className="w-6 h-6 ml-2 mr-2"
                  />
                </button>
                <button
                  className="bg-white text-green-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 transform hover:scale-105 hover:-translate-y-2 transition-transform duration-300 ease-in-out"
                  type="button"
                  onClick={() => {
                    window.open("https://wa.me/264814654657");
                  }}
                >
                  <img
                    src={WhatsappIcon}
                    alt="Facebook"
                    className="w-6 h-6 ml-2 mr-2"
                  />
                </button>
                <button
                  className="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 transform hover:scale-105 hover:-translate-y-2 transition-transform duration-300 ease-in-out"
                  type="button"
                  onClick={() => {
                    window.open("mailto:koets@iway.na");
                  }}
                >
                  <img
                    src={MailIcon}
                    alt="Facebook"
                    className="w-6 h-6 ml-2 mr-2"
                  />
                </button>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex flex-wrap items-top mb-6">
                <div className="w-full lg:w-4/12 px-4 ml-auto">
                  <span className="block uppercase text-desert-200 text-sm font-semibold mb-2 ">
                    Useful Links
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <Link
                        className="text-blueGray-600 hover:text-desert-200 font-semibold block pb-2 text-sm"
                        to="/review"
                      >
                        Post a Review
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="text-blueGray-600 hover:text-desert-200 font-semibold block pb-2 text-sm"
                        to="/booking"
                      >
                        Booking
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="text-blueGray-600 hover:text-desert-200 font-semibold block pb-2 text-sm"
                        to="/tours"
                      >
                        Packages
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="text-blueGray-600 hover:text-desert-200 font-semibold block pb-2 text-sm"
                        to="/contact"
                      >
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <span className="block uppercase text-desert-200 text-sm font-semibold mb-2">
                    Other Resources
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <Link
                        className="text-blueGray-600 hover:text-desert-200 font-semibold block pb-2 text-sm"
                        to="/terms"
                      >
                        Terms & Conditions
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="text-blueGray-600 hover:text-desert-200 font-semibold block pb-2 text-sm"
                        to="/privacy"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-6 border-blueGray-300" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-blueGray-800 font-semibold py-1">
                Copyright © {new Date().getFullYear()}{" "}
                <Link
                  to="https://stackhousess.dev/"
                  className="text-tahiti-600 hover:text-desert-200"
                >
                  Stackhouse Software Solutions
                </Link>
                .
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
