/* eslint-disable react-hooks/exhaustive-deps */

import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { functions } from "../../_config/firebaseConfig";
import Banners from "../../components/banners/Banners";
import Footer from "../../components/home/footer";
import Navbar from "../../components/home/navbar";
import { useStateReducer } from "../../hooks";
import ReviewForm from "../../interfaces/ReviewForm";
import service from "../../services/Reviews/reviewService";
import { useNavigate } from "react-router-dom";
import bookingService from "../../services/booking/bookingService";
import routes from "../../_config/routes";
import ReCAPTCHA from "react-google-recaptcha";

interface ReviewDataProps {
  reviewForm?: ReviewForm;
}

interface State {
  id?: string;
  name?: string;
  email?: string;
  title?: string;
  comment?: string;
  date?: string;
  rating?: number;
  status?: string;
  errors: {
    name?: string;
    email?: string;
    title?: string;
    recaptcha?: string;
  };
  loading: boolean;
}

function ReviewPage({ reviewForm }: ReviewDataProps) {
  const [state, setState] = useStateReducer<State>({
    name: reviewForm?.name || "",
    email: reviewForm?.email || "",
    title: reviewForm?.title || "",
    comment: reviewForm?.comment || "",
    date: reviewForm?.date || "",
    status: reviewForm?.status || "",
    rating: reviewForm?.rating || 1,
    errors: {},
    loading: false,
  });
  const [recaptchaResponse, setRecaptchaResponse] = useState<string | null>(
    null
  );
  const navigate = useNavigate();

  const siteKey = process.env.REACT_APP_SITE_KEY ?? "";
  const handleChange = (prop: string, value: any) => {
    setState({ ...state, [prop]: value });
  };

  const handleRatingChange = (rating: number) => {
    setState({ ...state, rating });
  };

  const validateForm = () => {
    const errors: { [key: string]: string } = {};

    if (!state.name) {
      errors.name = "Oops! Your name is required.";
    }

    if (!state.email) {
      errors.email = "Oops! Your email is required.";
    } else if (!isValidEmail(state.email)) {
      errors.email = "Oops! Invalid email address.";
    }

    if (!state.title) {
      errors.title = "Oops! Don't forget to add a catchy title.";
    }
    if (!recaptchaResponse) {
      errors.recaptcha = "Please verify that you're not a robot";
    }

    setState({ ...state, errors });
    return Object.keys(errors).length === 0;
  };

  const isValidEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };
  const handleRecaptchaChange = (response: string | null) => {
    setRecaptchaResponse(response);
    state.errors.recaptcha = "";
  };

  const handleSubmit = async (e?: any) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setState({ ...state, loading: true });
        console.log(state);
        const response = await service.createReview(state); // Pass state as data
        const sendEmailFunction = httpsCallable(functions, "/sendReviewEmail");
        await sendEmailFunction(state as ReviewForm);

        if (response?.success === true) {
          await bookingService.verifyRecaptcha(recaptchaResponse || "");
          navigate(routes.success.raw);
        } else {
          alert("Oops! Failed to post your review. Please try again.");
        }
        return null;
      } catch (error) {
        console.error("Error sending review:", error);
      } finally {
        setState({ ...state, loading: false }); // Reset loading state in case of an error
      }
    }
  };

  useEffect(() => {
    setState({ ...state, errors: {} });
  }, [state.name, state.title]);

  return (
    <>
      <Navbar transparent />
      <main>
        <Banners
          home={false}
          hasButton={false}
          title="Share Your Experience"
          imageUrl="./banners/banner5.jpg"
        />

        <section className="pb-20 relative block bg-white">
          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-tahiti-800 mt-8">
                  Your Experience Matters
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-8 text-tahiti-800">
                  Share your amazing experience with Sandwich & Namibia Travel
                  and Tours. We can&apos;t wait to hear from you!
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="relative block py-24 lg:pt-0 bg-white">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-desert-200">
                  <div className="flex-auto p-5 lg:p-10">
                    <h4 className="text-2xl font-semibold">Ready to Share?</h4>
                    <p className="leading-relaxed mt-1 mb-4 text-blueGray-600">
                      Your feedback means the world to us. Fill out the form
                      below and let the world know about your experience!
                    </p>
                    <div className="relative w-full mb-3 mt-8">
                      <label
                        className="block uppercase text-blueGray-900 text-xs font-bold mb-2"
                        htmlFor="full-name"
                      >
                        Your Full Name
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-600 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Your Full Name"
                        onChange={(e) => handleChange("name", e.target.value)}
                      />
                      {state.errors.name && (
                        <span className="text-red-600">
                          {state.errors.name}
                        </span>
                      )}
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-900 text-xs font-bold mb-2"
                        htmlFor="email"
                      >
                        Your Email
                      </label>
                      <input
                        type="email"
                        className="border-0 px-3 py-3 placeholder-blueGray-600 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Your Email"
                        onChange={(e) => handleChange("email", e.target.value)}
                      />
                      {state.errors.email && (
                        <span className="text-red-600">
                          {state.errors.email}
                        </span>
                      )}
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-900 text-xs font-bold mb-2"
                        htmlFor="title"
                      >
                        Catchy Title for Your Review
                      </label>
                      <input
                        type="title"
                        className="border-0 px-3 py-3 placeholder-blueGray-600 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Catchy Title"
                        onChange={(e) => handleChange("title", e.target.value)}
                      />
                      {state.errors.title && (
                        <span className="text-red-600">
                          {state.errors.title}
                        </span>
                      )}
                    </div>
                    <div className="flex items-center mb-6 space-x-1 rtl:space-x-reverse mt-6">
                      {Array.from({ length: 5 }).map((_, index) => (
                        <svg
                          key={index}
                          className={`w-6 h-6 ${
                            index < state.rating!
                              ? "text-yellow-300"
                              : "text-gray-300"
                          }`}
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 22 20"
                          onMouseEnter={() => handleRatingChange(index + 1)} // Handle hover
                          onClick={() => handleRatingChange(index + 1)} // Handle click
                        >
                          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                        </svg>
                      ))}
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-900 text-xs font-bold mb-2"
                        htmlFor="comment"
                      >
                        Your Review
                      </label>
                      <textarea
                        rows={4}
                        cols={80}
                        className="border-0 px-3 py-3 placeholder-blueGray-600 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Tell us about your experience..."
                        onChange={(e) =>
                          handleChange("comment", e.target.value)
                        }
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      {siteKey && (
                        <ReCAPTCHA
                          sitekey={siteKey}
                          onChange={handleRecaptchaChange}
                        />
                      )}
                      {state.errors.recaptcha && (
                        <span className="text-red-600">
                          {state.errors.recaptcha}
                        </span>
                      )}
                    </div>
                    <div className="text-center mt-6">
                      <button
                        className="bg-white text-desert-200 active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        disabled={state.loading}
                        onClick={handleSubmit}
                      >
                        Share Your Experience
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
export default ReviewPage;
