"use client";

import { useNavigate } from "react-router-dom";
import routes from "../../_config/routes";

interface BannerProps {
  home: boolean;
  hasButton: boolean;
  title?: string;
  subTitle?: string;
  imageUrl?: string;
  pathColor?: string;
  strokeColor?: string;
}

export default function Banners({
  home = false,
  hasButton = false,
  title = "Lorum Ipsum",
  subTitle = "",
  imageUrl = "/img/sandwich2.jpg",
  pathColor = "text-white",
  strokeColor = "text-desert-200",
}: BannerProps) {
  const navigate = useNavigate();

  return (
    <div
      className={`relative pt-16 pb-32 flex content-center items-center justify-center ${
        home ? "min-h-screen-75" : "h-96"
      }`}
    >
      <div
        className={`${
          home
            ? "absolute top-0 w-full h-full bg-center bg-cover bg-fixed"
            : "absolute -top-0 w-full h-full bg-center bg-cover opacity-50"
        }`}
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
      ></div>
      <div className="container relative mx-auto">
        <div className="items-center flex flex-wrap">
          <div className="w-full lg:w-8/12 px-4 ml-auto mr-auto text-center">
            <h1 className="text-white font-semibold text-5xl pt-24 md:text-6xl sm:text-4xl">
              {title}
            </h1>
            {subTitle && (
              <h2 className="mt-4 text-xl text-white">{subTitle}</h2>
            )}
            {hasButton && (
              <div className="text-center mt-6">
                <button
                  className="bg-desert-200 text-gray-100 hover:bg-gray-100 hover:text-desert-200 active:bg-blueGray-600 text-xl font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => navigate(routes.booking.raw)}
                >
                  Book Now
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="top-auto -bottom-2 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-16"
        style={{ transform: "translateZ(1)" }}
      >
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          {/* Grey line alongside the curves */}
          <path
            className={`${strokeColor} stroke-current`}
            strokeWidth="4"
            fill="none"
            d="M1380,0 Q1300,10 1400,30 Q1500,70 1600,20 Q1700,50 1800,20 Q1900,60 2000,40 Q2100,50 2200,50 Q2300,70 2400,20 Q2500,40 2550,40 Q2600,40 2600,100"
          ></path>

          {/* First curve */}
          <path
            className={`${pathColor} fill-current`}
            d="M1380,0 Q1300,10 1400,30 Q1500,70 1600,20 Q1700,50 1800,20 Q1900,60 2000,40 Q2100,50 2200,50 Q2300,70 2400,20 Q2500,40 2550,40 Q2600,40 2600,100 L100,90"
          ></path>
        </svg>
      </div>
    </div>
  );
}
