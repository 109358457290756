import {
  collection,
  getDocs,
  doc,
  getDoc,
  addDoc,
  query,
  limit,
  where,
} from "firebase/firestore";
import { db } from "../../_config/firebaseConfig"; // Import your Firebase Firestore instance here
import { GetSingleArgs, PageAndSearchArgs, PagedData } from "../../interfaces";

import { publish } from "../../hooks";
import Review from "../../interfaces/Reviews/Review";
import ReviewForm from "../../interfaces/ReviewForm";
import { StateTopicEnum } from "../../enums";

interface ReviewService {
  getReviewByPage: (args: PageAndSearchArgs) => Promise<PagedData<Review>>;
  getReviews: () => Promise<Review[]>;
  getReviewSingle: (args: GetSingleArgs) => Promise<any>;
  createReview: (data: ReviewForm) => Promise<any>;
}
const orderRef = collection(db, "Reviews");
const service: ReviewService = {
  getReviewByPage: async ({ search, pageNo }: PageAndSearchArgs) => {
    const pageSize = 13;
    try {
      const querySnapshot = await getDocs(orderRef);

      const reviews: Review[] = [];

      querySnapshot.forEach((doc) => {
        const reviewData = doc.data();
        reviewData.id = doc.id;

        reviews.push({
          id: reviewData.id,
          name: reviewData.name,
          email: reviewData.email,
          title: reviewData.title,
          comment: reviewData.comment,
          date: reviewData.date,
          rating: reviewData.rating,
          status: reviewData.status,
        });
      });
      return {
        pageNo: pageNo,
        pageSize: pageSize,
        pageCount: 1,
        itemCount: reviews.length,
        data: reviews,
      };
    } catch (error) {
      console.error("Firebase error:", error);
      throw error;
    }
  },
  getReviews: async () => {
    const querySnapshot = await getDocs(
      query(orderRef, where("status", "==", "active"), limit(10))
    );

    const reviews: Review[] = [];

    querySnapshot.forEach((doc) => {
      const reviewData = doc.data();
      reviewData.id = doc.id;

      reviews.push({
        id: reviewData.id,
        name: reviewData.name,
        email: reviewData.email,
        title: reviewData.title,
        comment: reviewData.comment,
        date: reviewData.date,
        rating: reviewData.rating,
        status: reviewData.status,
      });
    });

    return reviews;
  },
  getReviewSingle: async ({ id }: GetSingleArgs) => {
    try {
      const orderRef = doc(db, "Reviews", id);
      const docSnapshot = await getDoc(orderRef);

      if (docSnapshot.exists()) {
        const reviewData = docSnapshot.data();
        reviewData.id = docSnapshot.id;
        return reviewData as Review;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Firebase error:", error);
      throw error;
    }
  },
  createReview: async (data: ReviewForm) => {
    try {
      const orderRef = collection(db, "Reviews");
      const docRef = await addDoc(orderRef, data);
      publish(StateTopicEnum.ReloadUpdate, null);
      return { success: true, id: docRef.id };
    } catch (error) {
      console.error("Firebase error:", error);
      return { success: false, error: error };
    }
  },
};

export default service;
