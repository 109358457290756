import { useState, useEffect } from "react";
import Banners from "../../components/banners/Banners";
import Footer from "../../components/home/footer";
import Navbar from "../../components/home/navbar";
import { useStateReducer } from "../../hooks";
import PackageItem from "../../interfaces/PackageItem";
import service from "../../services/Package/packageService";
import { Link } from "react-router-dom";

interface State {
  package?: PackageItem[] | null;
  packageId?: string;
  isSaveDisabled?: boolean;
}
export default function ToursPage() {
  const [state, setState] = useStateReducer<State>({ isSaveDisabled: true });
  const [isLoading, setIsLoading] = useState(true);

  const load = async () => {
    try {
      const result: PackageItem[] = await service.getPackages();
      setState({ ...state, package: result });
    } catch (error) {
      // Handle errors
    }
  };

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    const delay = 2000;
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, [state.package]);

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <img
          src="icons/jeep.gif"
          alt="Loading"
          width={"50px"}
          height={"50px"}
        />
      </div>
    );
  }
  return (
    <>
      {/* <Helmet title="We have the right Tours just for you!" /> */}
      {!isLoading && state.package ? (
        <>
          <Navbar transparent />
          <main>
            <Banners
              home={false}
              hasButton={false}
              title="Tour Packages"
              imageUrl="./banners/banner4.jpg"
            />

            <section className="pb-32 pt-20 bg-white -mt-2 ">
              <div className="container mx-auto px-4 ">
                <div className="flex flex-wrap justify-center items-stretch">
                  {state.package?.map((item, index) => (
                    <div
                      className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 px-2 mb-4 flex"
                      key={index}
                    >
                      <Link
                        to={`/tours/${item.id}`}
                        className="transform hover:scale-105  rounded-lg p-2 transition-transform duration-300 flex flex-col h-full"
                      >
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded-lg flex-1">
                          <img
                            alt="..."
                            src={item.image_url}
                            className="h-350-px align-middle rounded-lg transform hover:-translate-y-6 transition-transform duration-300"
                          />
                          <blockquote className="relative p-8 mb-4">
                            <svg
                              preserveAspectRatio="none"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 583 95"
                              className="absolute left-0 w-full block h-95-px -top-94-px"
                            >
                              <polygon
                                points="-30,95 583,95 583,65"
                                className="text-white fill-current"
                              ></polygon>
                            </svg>
                            <h4 className="text-md font-bold mt-2 text-black ">
                              {item?.title}
                            </h4>
                            <p
                              className="text-md font-light mt-2 text-black overflow-hidden"
                              style={{
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                overflow: "ellipses",
                                WebkitLineClamp: 4,
                                maxHeight: "6em", // You can adjust this based on your font size and line height
                              }}
                            >
                              {item?.description}
                            </p>
                          </blockquote>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </main>
          <Footer />
        </>
      ) : null}
    </>
  );
}
