import Banners from "../../components/banners/Banners";
import Footer from "../../components/home/footer";
import Navbar from "../../components/home/navbar";

export default function PrivacyPolicyPage() {
  return (
    <>
      <Navbar transparent />
      <main>
        <Banners
          home={false}
          hasButton={false}
          title="Privacy Policy"
          imageUrl="./banners/banner5.jpg"
        />

        <section className="relative pt-16 pb-32">
          <div className="bg-white min-h-screen py-8">
            <div className="container mx-auto px-4">
              <h1 className="text-3xl font-semibold text-center my-4">
                Privacy Policy
              </h1>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <p>
                  This Privacy Policy describes how Sandwich & Namibia Travel
                  and Tours (&quot;we,&quot; &quot;us,&quot; or &quot;our&quot;)
                  collects, uses, and protects the information we gather from
                  visitors to our website and users of our services.
                </p>

                <h2 className="text-xl font-semibold my-4">
                  1. Information We Collect
                </h2>
                <p>
                  We may collect personal information, including but not limited
                  to, names, email addresses, phone numbers, and preferences
                  when provided voluntarily by users.
                </p>

                <h2 className="text-xl font-semibold my-4">
                  2. How We Use Your Information
                </h2>
                <p>
                  We use the information collected to provide and improve our
                  services, communicate with users, process bookings, and
                  personalize user experiences. We do not sell or rent personal
                  information to third parties.
                </p>

                <h2 className="text-xl font-semibold my-4">3. Data Security</h2>
                <p>
                  We implement security measures to protect the confidentiality
                  of personal information. However, no method of transmission
                  over the internet or electronic storage is completely secure,
                  and we cannot guarantee absolute security.
                </p>

                <h2 className="text-xl font-semibold my-4">
                  4. Cookies and Tracking Technologies
                </h2>
                <p>
                  We use cookies and similar technologies to enhance user
                  experiences, analyze website traffic, and customize content.
                  Users can control the use of cookies through their browser
                  settings.
                </p>

                <h2 className="text-xl font-semibold my-4">
                  5. Third-Party Links
                </h2>
                <p>
                  Our website may contain links to third-party websites. We are
                  not responsible for the privacy practices or content of
                  third-party sites. Users should review the privacy policies of
                  these sites.
                </p>

                <h2 className="text-xl font-semibold my-4">
                  6. Updates to Privacy Policy
                </h2>
                <p>
                  We reserve the right to update this Privacy Policy as needed.
                  Users are encouraged to check this page regularly for any
                  changes. By continuing to use our services, users accept any
                  modifications to the Privacy Policy.
                </p>

                <p>
                  If you have any questions about this Privacy Policy, please
                  contact us at [your contact information].
                </p>

                {/* Add more sections and details as needed */}
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
