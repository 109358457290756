/* eslint-disable react-hooks/exhaustive-deps */
"use client";
import { useEffect } from "react";
import PackageItem from "../../interfaces/PackageItem";
import packageService from "../../services/Package/packageService";
import { useStateReducer } from "../../hooks";
import Banners from "../../components/banners/Banners";
import ContactFormComponent from "../../components/contact/ContactForm";
import Carousel from "../../components/home/carousel";
import Navbar from "../../components/home/navbar";
import Footer from "../../components/home/footer";
import {
  faBinoculars,
  faCar,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface State {
  package?: PackageItem[] | null;
  packageId?: string;
}
export default function HomePage() {
  const [state, setState] = useStateReducer<State>({});

  const load = async () => {
    try {
      const result: PackageItem[] = await packageService.getPopularPackages();
      setState({ ...state, package: result });
    } catch (error) {
      // Handle errors
    }
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <>
      <Navbar transparent={true} />

      <main>
        {/* <Helmet title="Experience of a Lifetime!" /> */}
        {/* Header Section */}
        <Banners
          home={true}
          hasButton={true}
          title="Discover Namibia's Hidden Wonders"
          subTitle="Embark on Unforgettable Adventures with Us"
          pathColor="text-gray-100"
          strokeColor="text-desert-200"
          imageUrl="./img/sandwich.jpg"
        />

        {/* Included  Section */}
        <section className="relative bg-gray-100 pt-8 pb-6 backdrop-blur-xl shadow-lg">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="transform hover:scale-105 hover:-translate-y-6 transition-transform duration-300 ease-in-out block pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-20 h-20 mb-5 shadow-lg rounded-full bg-desert-200">
                      <FontAwesomeIcon
                        icon={faBinoculars}
                        className="text-white text-3xl"
                      />
                    </div>
                    <h6 className="text-xl font-semibold">Experience!</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      Unleash the thrill with our unique experiences over the
                      dunes and around Namibia. Discover Sandwich Harbour &
                      Namibia and all its glory.
                    </p>
                  </div>
                </div>
              </div>

              <div className="transform hover:scale-105 hover:-translate-y-6 transition-transform duration-300 ease-in-out block pt-0 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-20 h-20 mb-5 shadow-lg rounded-full bg-desert-200">
                      <FontAwesomeIcon
                        icon={faUtensils}
                        className="text-white text-3xl"
                      />
                    </div>
                    <h6 className="text-xl font-semibold">Catering</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      Elevate your journey with our impeccable catering,
                      tailored to your travel needs and including delightful
                      vegetarian options.
                    </p>
                  </div>
                </div>
              </div>

              <div className="transform hover:scale-105 hover:-translate-y-6 transition-transform duration-300 ease-in-out block pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-20 h-20 mb-5 shadow-lg rounded-full bg-desert-200">
                      <FontAwesomeIcon
                        icon={faCar}
                        className="text-white text-3xl"
                      />
                    </div>
                    <h6 className="text-xl font-semibold">Namibia</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      Immerse yourself in the essence of Namibia, a land waiting
                      to be discovered with its captivating landscapes and
                      hidden treasures.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* About us Section */}
        <section className="relative pt-16 pb-32">
          <div className="flex flex-wrap items-center mt-4 ">
            <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
              <h3 className="text-3xl mb-2 font-semibold leading-normal">
                Welcome to Sandwich & Namibia Travel and Tours!
              </h3>
              <h3 className="text-lg mb-2 font-normal leading-normal text-desert-200">
                - &ldquo;Where Every Journey Becomes a Story&rdquo;
              </h3>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                Embark on a journey like never before with Sandwich & Namibia
                Travel and Tours. As a family-owned company, we take pride in
                offering unique and unforgettable experiences across the
                breathtaking landscapes of Namibia.
              </p>
              <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                Our passion for exploration and deep love for Namibia inspire us
                to curate exceptional tours that showcase the rich diversity of
                this beautiful country. From the vast deserts to lush national
                parks, we invite you to discover the wonders of Namibia through
                our expertly crafted journeys.
              </p>
              <a
                href="/tours"
                className="font-bold text-desert-300 mt-8 hover:text-desert-200"
              >
                Explore Namibia with Us
              </a>
            </div>

            <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ">
                <img
                  alt="..."
                  src="./banners/fortuner.jpg"
                  className="w-full align-middle rounded-lg"
                />
              </div>
            </div>
            <div
              className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-16 "
              style={{ transform: "translateZ(0)" }}
            >
              <svg
                className="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="text-gray-100 fill-current"
                  points="2560 0 2560 100 0 100"
                ></polygon>
              </svg>
            </div>
          </div>
        </section>

        {/* Packages Section */}
        <section className="pb-32 pt-20 bg-gray-100 -mt-2 ">
          <div className="container mx-auto px-4 ">
            <div className="items-center flex flex-wrap pb-20">
              <div className="w-full lg:w-8/12 px-4 ml-auto mr-auto text-center">
                <h1 className=" font-semibold text-5xl text-desert-200">
                  Explore Our Exciting Packages
                </h1>
                <p className="mt-4 text-lg text-black">
                  Embark on an adventure with our carefully crafted packages.
                  Whether you seek thrilling experiences or serene getaways, we
                  have the perfect journey for you. Discover Namibia in style!
                </p>
              </div>
            </div>
            <div className="flex flex-wrap justify-center items-stretch">
              {state.package?.map((item, index) => (
                <div
                  className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 px-2 mb-4 flex"
                  key={index}
                >
                  <a
                    href={`/tours/${item.id}`}
                    className="transform hover:scale-105  rounded-lg p-2 transition-transform duration-300 flex flex-col h-full"
                  >
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded-lg flex-1">
                      <div className="absolute top-2 right-2 bg-desert-300 text-gray-100 px-2 py-1 rounded z-10">
                        {item?.tag}
                      </div>
                      <img
                        alt="..."
                        src={item.image_url}
                        className="h-350-px align-middle rounded-lg transform hover:-translate-y-6 transition-transform duration-300"
                      />
                      <blockquote className="relative p-8 mb-4 flex-grow">
                        <svg
                          preserveAspectRatio="none"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 583 95"
                          className="absolute left-0 w-full block h-95-px -top-94-px"
                        >
                          <polygon
                            points="-30,95 583,95 583,65"
                            className="text-white fill-current"
                          ></polygon>
                        </svg>
                        <h4 className="text-xl font-bold text-black">
                          {item?.title}
                        </h4>
                        <p
                          className="text-md font-light mt-2 text-black overflow-hidden"
                          style={{
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            overflow: "ellipses",
                            WebkitLineClamp: 4,
                            maxHeight: "6em", // You can adjust this based on your font size and line height
                          }}
                        >
                          {item?.description}
                        </p>
                      </blockquote>
                      <div className="text-center mb-6">
                        <button
                          className="bg-desert-200 text-gray-100 hover:bg-gray-100 hover:text-desert-200 active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                        >
                          Book Now
                        </button>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* Small Hero By Section */}
        <section className=" pt-20 -mt-24 backdrop-blur-xl shadow-xl rounded-lg shadow-grey-100">
          <div className="relative pt-16 pb-32 flex content-center items-center justify-center h-75">
            <div
              className="absolute top-0 w-full h-full bg-center bg-cover bg-fixed"
              style={{
                backgroundImage: "url('./banners/banner5.jpg')",
              }}
            ></div>
            <div className="container relative mx-auto">
              <div className="items-center flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4 ml-0 mr-0 text-left">
                  <h1 className="font-semibold text-5xl text-desert-400">
                    Explore Captivating Tours
                  </h1>
                  <p className="mt-4 text-lg text-white">
                    Embark on a journey of discovery with our extraordinary
                    tours. Immerse yourself in the beauty of new places,
                    cultures, and adventures. Your unforgettable travel
                    experience starts here.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Reviews Section */}
        <section className="relative bg-gray-100 mt-1 pt-6 pb-6 backdrop-blur-xl shadow-lg rounded-lg">
          <div className="items-center flex flex-wrap pb-20">
            <div className="w-full lg:w-5/12 px-4 ml-auto mr-auto text-center">
              <h1 className=" font-semibold text-5xl text-desert-200">
                Hear What Our Travelers Say
              </h1>
              <p className="mt-4 text-lg text-black">
                Immerse yourself in the experiences of our satisfied travelers.
                Read their stories and testimonials about the unforgettable
                journeys they&apos;ve had with us. Join the community of
                explorers and let their reviews inspire your next adventure!
              </p>
            </div>
          </div>
          <Carousel />
        </section>

        {/* Contact Section */}
        <ContactFormComponent />
      </main>
      <Footer />
    </>
  );
}
