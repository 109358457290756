/* eslint-disable jsx-a11y/img-redundant-alt */
import { useState } from "react";
import Slider from "react-slick";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useStateReducer } from "../../hooks";

interface State {
  photos?: any[] | null;
  access_token?: string;
}

function GalleryCarousel({ photos, access_token }: any) {
  const [state, setState] = useStateReducer<State>({
    photos: photos,
    access_token: access_token,
  });
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(0);

  const openLightbox = (index: number) => {
    setLightboxOpen(true);
    setActiveItem(index);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setActiveItem(0);
  };

  const lightboxImages =
    state.photos?.map((item) => ({
      src: `https://graph.facebook.com/${item?.id}/picture?type=normal&access_token=${access_token}`,
    })) || [];
  const CustomPrevArrow = (props: any) => (
    <button
      className="prev-arrow absolute bottom-0 lg:-left-40 md:-left-40 -left-14 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-black opacity-100 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-desert-200 hover:no-underline hover:opacity-90 hover:outline-none focus:text-desert-200 focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
      type="button"
      onClick={props.onClick}
    >
      <span className="inline-block h-8 w-8">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="h-8 w-8"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 19.5L8.25 12l7.5-7.5"
          />
        </svg>
      </span>
      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
        {"<"}
      </span>
    </button>
  );

  const CustomNextArrow = (props: any) => (
    <button
      className="next-arrow absolute bottom-0 lg:-right-40 md:-right-40 -right-14 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-black opacity-100 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-desert-200 hover:no-underline hover:opacity-100 hover:outline-none focus:text-desert-200 focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
      type="button"
      onClick={props.onClick}
    >
      <span className="inline-block h-8 w-8">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="h-8 w-8"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.25 4.5l7.5 7.5-7.5 7.5"
          />
        </svg>
      </span>
      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
        {">"}
      </span>
    </button>
  );
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    centerMode: true,
    useCSS: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          adaptiveHeight: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          adaptiveHeight: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          adaptiveHeight: true,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <>
      <div className="mx-auto px-4 lg:px-5 pb-8 pt-8 bg-white shadow-lg rounded-lg">
        <Slider {...settings}>
          {state.photos?.map((item, index) => (
            <div key={index} className="max-w-full px-2 pb-12 ">
              <img
                className="object-contain w-full h-96 rounded-lg cursor-pointer"
                src={`https://graph.facebook.com/${item?.id?.toString()}/picture?type=normal&access_token=${access_token}`}
                alt={`Photo ${index + 1}`}
                onClick={() => openLightbox(index)}
              />
            </div>
          ))}
        </Slider>
        <Lightbox
          open={lightboxOpen}
          close={closeLightbox}
          plugins={[Zoom]}
          slides={lightboxImages}
          index={activeItem}
        />
      </div>
    </>
  );
}

export default GalleryCarousel;
